@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');


* { 
  margin: 0px;
  padding: 0px;
}

p {
  margin-bottom: 0px;
}

:root {
  --primary: #007CC2;
  --secondary: #FFB900;
  --tertiary: #707070;
  --gray: #015941;
  --success: #00CC95;
  --lightgray: #E8E8E8;
}

.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-brand {
  font-family: 'Bebas Neue', cursive!important;
  font-size: 2.5rem!important;
  color: white!important;
}

.navbar-brand > span {
  color: var(--secondary);
}

.navbar-item {
  color: white;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px 1rem;
  font-size: .8rem;
  position: relative;
}

.navbar-item:hover {
  color: var(--secondary);
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 12px solid var(--secondary);
  position: absolute;
  bottom: -53px;
  left: 50%;
  margin-left: -6px;
  transition: bottom 1.2s;
}

.navbar-item.active {
  color: var(--secondary)
}

.navbar-item.active > .triangle-up {
  bottom: -33px;
}
.bi-whatsapp {
  fill: white;
  width: 25px;
  height: 25px;
}

.mobile-menu {
  display: none;
}

.bg-blue {
  background-color: var(--primary)
}

.bg-orange {
  background-color: var(--secondary);
  padding: .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray)
}


/* Hero */
header {
  position: fixed;
  top: 0px;
  z-index: 99;
  left: 0px;
  right: 0px;
}

.hero {
  background-image: url('./images/car.jpeg');
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;

}

.hero-contact {
  background-image: url('./images/contact.jpg');
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;

}

.hero-about {
  background-image: url('./images/about-us.jpg');
  background-size: cover;
  height: 320px;
  width: 100%;
  position: relative;

}



.hero-body, .hero-body .container , .hero-body .row {
  height: 100%;
}


.hero-text {
  z-index: 10;
  position: relative;
}

h1.title {
  font-family: 'Bebas Neue', cursive;
  font-size: 4rem;
  margin-bottom: 0px;
}

h2.title {
  font-family: 'Bebas Neue', cursive;
  font-size: 3rem;
}

h2.subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
}

h3.subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

h4.subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
}



.height-100 {
  height: 100%;
}

.icons {
  display: flex;
  justify-content: space-around;
}

.icons > div > span {
  font-size: 80px;
}

.primary-color {
  color: var(--primary);
}

.secondary-color {
  color: var(--secondary);
}

.tertiary-color {
  color: var(--tertiary);
}

.quartiary-color {
  color: var(--gray);
}

.overlay {
  background-color: rgba(0, 124, 194, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  backdrop-filter: blur(4px);
}


.icons-section {
  padding: 6rem 0px;
}

.steps {
  padding: 6rem 0px;
  background-color: #707070;
}

.steps-columns {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

.number {
  width: 184px;
  height: 184px;
  background-color: white;
  display: flex;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 80px;
  font-family: 'Bebas Neue', cursive;
  color:#707070;
  margin-bottom: 1rem;
}

.step-wrap {
  flex-grow: 1;
  padding: 1rem 2rem;
}

.approve {
  padding: 6rem 0px;
}

footer {
  background-color: #015941;
  padding: 3rem 0px 0px;
}

.dark-bg {
  background-color: #282c34;
  padding: .5rem;
  color: white;
  text-transform: uppercase;
}

.dark-bg span {
  color: var(--secondary)
}

.btn.btn-success {
  background-color: var(--success)!important;
  border: 0px;
  padding: .75rem 1.5rem;
  display: flex;
}

.link-footer {
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: .9rem;
  padding: .4rem 0px;
  transition: color .7s;
}

.link-footer:hover {
  color: var(--secondary)
}

.icons-footer {
  display: grid;
  grid-template-columns: auto auto;
}

.icons-footer > a > svg {
  fill: white;
  width: 30px;
  height: 30px;
  margin: 1rem;
  transition: fill .7s;
} 

.icons-footer > a > svg:hover {
  fill: var(--secondary);

} 

.form {
  background-color: white;
  padding: 2rem .5rem;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  border-radius: 10px;
  max-width: 400px;
} 

.location.is-hidden {
  display: none;
}
  
.has-padding-top-bottom {
  padding: 6rem 0rem;
}

.gray-bg {
  background-color: var(--lightgray);
}

.card-body {
  opacity: 0;
  display: none;
  transition: opacity .7s;
}

.card-body.isVisible {
  display: block;
  opacity: 1;
}

.card {
  border: 0px!important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  align-items: center;
}

.material-symbols-sharp {
  transition: transform .7s;
}

.flip {
  transform: rotate(180deg);
}

.card-body {
  background-color: rgba(255, 185, 0, 0.1);
  text-align: left;
}

.card-header h2 {
  font-family: 'Open Sans', sans-serif;
  pointer-events: none;
  font-size: 1.2rem;
}

.card-header span {
  pointer-events: none;
}

.rotate {
  animation: spinning infinite forwards .5s;
}

.row {
  margin-left: 0px!important;
  margin-right: 0px!important;
}

.phonenumber {
  z-index: 5;
  position: relative;
}

@keyframes spinning {

  0% {transform: rotate(0deg)}
  50% {transform: rotate(180deg)}
  100% { transform: rotate(359deg)}
  
}

.border-radius {
  border-radius: 10px;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.2);
}





@media only screen and (max-width: 1023px) {

  .triangle-up {
    display: none;
  }

  .hero, .hero-contact {
    margin-top: 116px;
  }



.navbar-collapse.show {
  transform: translateY(100%);
  transition: transform .7s;
}

  .navbar-collapse {
    position: fixed;
    top: 116px;
    left: 0px;
    background-color: var(--gray);
    width: 100%;
    bottom: 0px;
    transform: translateY(0);
    transition: transform .7s;
  }

  .navbar-collapse > ul {
    padding-top: 1rem;
    flex-direction: column;
    row-gap: 1rem;
  } 


  h1.title {
    font-family: 'Bebas Neue', cursive;
    font-size: 3rem;
    margin-bottom: 0px;
  }
  
  h2.title {
    font-family: 'Bebas Neue', cursive;
    font-size: 2rem;
  }
  
  h2.subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
  }
  
  h3.subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
  }
  
  h4.subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
  }
  


  .steps-columns {
    display: grid;
    grid-template-columns: auto;
    justify-content: space-between;
  }

  .icons {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }


  .mobile-menu {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    border: 0px;
  }

  .mobile-menu p {
    font-size: .7rem;
    margin-bottom: 0px;
  }

}

@media only screen and (max-width: 576px) {
  .hero {
    height: 80vh;
  }

  .card-header h2 {
    font-size: .9rem;
    text-align: left;
    line-height: 1.4rem;
  }

  .has-padding-top-bottom {
    padding: 2rem .5rem;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
